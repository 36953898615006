import { Pagination, Card, Divider, Button } from 'antd';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PerformBlockchainSavings from './PerformBlockchainSavings';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { getBlockchainTransactionRecords } from '../../methods/redux/reducers/blockchain-transactions';
import BlockchainTransactionItem from './BlockchainTransactionItem';
import SearchFilter from '../../component/Filter';
import remakeQueryString from '../../methods/utils/remake-query-string';

const BlockchainTransactions = () => {
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const { transactions } = useAppSelector(
		(store) => store.blockchainTransactions
	);
	const { currencies, blockchains } = useAppSelector(
		(store) => store.currencies
	);

	const currencySelect = currencies.map((item: any) => ({
		name: item.symbol,
		value: item._id,
	}));

	const blockchainSelect = blockchains.map((item: any) => ({
		name: item.abbreviation,
		value: item._id,
	}));

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				actionType: 'deposit_savings',
				pageId: '1',
				perPage: String(transactions.paginator.perPage),
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length > 0) {
			dispatch(getBlockchainTransactionRecords(queryData));
		}
	}, [searchParams]);

	const blockchainTransactionActionTypes = [
		{ name: 'Deposit Savings', value: 'deposit_savings' },
		{ name: 'Savings Withdrawal', value: 'withdrawal_savings' },
		{ name: 'Staking', value: 'staking' },
	];

	return (
		<div>
			<Card>
				<h2>Blockchain Transactions</h2>
				<p>
					Manage blockchain transactions need to processes in the app like
					savings, staking etc.
				</p>
				<br />
				<PerformBlockchainSavings
					blockchains={blockchainSelect}
					currencies={currencySelect}
					actionType={blockchainTransactionActionTypes}
				/>
			</Card>
			<br />
			<Card>
				<h2>Lending Zones</h2>
				<p>Manage blockchain Lending / yield Process</p>
				<div style={{ display: 'flex', gap: '10px', marginTop: '15px' }}>
					<Button type="default">
						<a
							href=" https://autoyield.xend.africa/"
							target="_blank"
							rel="noopener noreferrer"
						>
							xAuto
						</a>
					</Button>
					<Button type="default">
						<a
							href="https://fluid.instadapp.io/lending/8453"
							target="_blank"
							rel="noopener noreferrer"
						>
							Fluid Lending
						</a>
					</Button>
				</div>
				<br />
			</Card>
			<br />
			<Card>
				<SearchFilter
					title="Search Blockchain Transaction Records"
					searchFilters={[
						{
							label: 'Type of Transaction',
							name: 'actionType',
							htmlElement: 'select',
							defaultSelectedValue: 'deposit_savings',
							selectOptions: blockchainTransactionActionTypes,
						},
						{
							label: 'Currency',
							name: 'currencyId',
							htmlElement: 'select',
							selectOptions: currencySelect,
						},
						{
							label: 'Blockchain Network',
							name: 'blockchainNetworkId',
							htmlElement: 'select',
							selectOptions: blockchainSelect,
						},
					]}
					retrieveQueryString={updateQueryString}
				/>

				<br />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={transactions.paginator.itemCount}
					current={transactions.paginator.currentPage}
					pageSize={transactions.paginator.perPage}
				/>
				<br />
				{transactions.itemsList.map((transaction: any) => (
					<BlockchainTransactionItem
						key={transaction._id}
						transaction={transaction}
						actionTypes={blockchainTransactionActionTypes}
					/>
				))}

				<Divider />

				<Pagination
					onChange={(pageId) => updateQueryString({ pageId })}
					total={transactions.paginator.itemCount}
					current={transactions.paginator.currentPage}
					pageSize={transactions.paginator.perPage}
					showSizeChanger
					showQuickJumper
				/>
			</Card>
		</div>
	);
};

export default BlockchainTransactions;
