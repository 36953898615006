import { useEffect, useState } from 'react';
import { Button, Input, Modal, Space } from 'antd';
import { useAppDispatch } from '../../methods/redux/hooks';
import { updateSupportedCurrency } from '../../methods/redux/reducers/currency';

interface IEditCurrency {
	currency: any;
}

const EditCurrency = ({ currency }: IEditCurrency) => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	const [currencyData, setCurrencyData] = useState({
		currencyId: '',
		name: '',
		symbol: '',
		decimalPrecision: '',
		minimumWithdrawalAmount: '',
		coinGeckoId: '',
		switchWalletId: '',
		iconUrl: '',
		currencyForm: '',
	});

	const handleInput = (e: any) => {
		const { name, value } = e.target;
		setCurrencyData({ ...currencyData, [name]: value });
	};

	useEffect(() => {
		setCurrencyData({ ...currencyData, ...currency, currencyId: currency._id });
	}, [currency]);

	const handleCurrencyUpdate = () => {
		dispatch(updateSupportedCurrency({ ...currencyData }));
		setVisible(false);
	};

	return (
		<div>
			<Button onClick={() => setVisible(true)}>Edit</Button>

			<Modal
				okText="Save Update"
				onOk={handleCurrencyUpdate}
				visible={visible}
				onCancel={() => setVisible(false)}
				title="Edit Currency"
			>
				<Space direction="vertical" size={10} style={{ width: '100%' }}>
					<div>
						<p>Name</p>
						<Input
							name="name"
							value={currencyData.name}
							onChange={handleInput}
							size="large"
							placeholder="Name"
						/>
					</div>
					<div>
						<p>Symbol</p>
						<Input
							name="symbol"
							readOnly
							value={currencyData.symbol}
							size="large"
							placeholder="Symbol"
						/>
					</div>
					<div>
						<p>Decimal Precision</p>
						<p>
							Decimal precision used in the app (not the crypto currency
							decimals from the token smart contract)
						</p>
						<Input
							name="decimalPrecision"
							value={currencyData.decimalPrecision}
							onChange={handleInput}
							size="large"
							placeholder="Decimal Precision"
						/>
					</div>
					<div>
						<p>Minimum Withdrawal Amount</p>
						<Input
							name="minimumWithdrawalAmount"
							value={currencyData.minimumWithdrawalAmount}
							onChange={handleInput}
							size="large"
							placeholder="Minimum Withdrawal Amount"
						/>
					</div>
					{currencyData.currencyForm === 'crypto' && (
						<div>
							<p>Switch Wallet Id</p>
							<Input
								name="switchWalletId"
								value={currencyData.switchWalletId}
								onChange={handleInput}
								size="large"
								placeholder="Switch Wallet Id"
							/>
						</div>
					)}
					{currencyData.currencyForm === 'crypto' && (
						<div>
							<p>CoinGecko Id</p>
							<Input
								name="coinGeckoId"
								value={currencyData.coinGeckoId}
								onChange={handleInput}
								size="large"
								placeholder="Coin Gecko Id"
							/>
						</div>
					)}
					<div>
						<p>Logo URL</p>
						<Input
							name="iconUrl"
							value={currencyData.iconUrl}
							onChange={handleInput}
							size="large"
							placeholder="Logo Url"
						/>
					</div>
					<div>
						<p>
							<strong>View Logo</strong>
						</p>
						<img
							src={currencyData.iconUrl}
							alt=""
							className="logo"
							style={{
								width: 50,
								height: 50,
							}}
						/>
					</div>
				</Space>
			</Modal>
		</div>
	);
};

export default EditCurrency;
