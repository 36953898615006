import { Divider, Input, Button, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	retrieveInternalSettings,
	updateInternalSettings,
} from '../../methods/redux/reducers/internal-settings';
import { SavingsSettingsStyle } from '../savings/SavingsSettings';

const SavingsSettings = () => {
	const dispatch = useAppDispatch();
	const settings = useAppSelector((store) => store.internalSettings);

	const [savingsSettings, setSavingsSettings] = useState({
		_id: '',
		maxAllowedStableCurrencyFlexibleSavingsCount: 0,
		maxAllowedStableCurrencyFixedSavingsCount: 0,
		maxAllowedStableCurrencySavingsTopUpCount: 0,
		updateButtonDisabled: true,
		xendSavingsRewardTokenAPR: 0,
		flexibleWithdrawalHours: 0,
		maxStableCurrencySavingAmounts: 0,
		minStableCurrencySavingAmounts: 0,
		fixedSavingsTopUpLifespan: 0,
		fixedSavingsTerminationPenaltyPercentage: 0,
		allowFlexibleAutoSave: false,
		allowFixedAutoSave: false,
		allowJoinEsusuMultiple: false,
		allowPublicViewEsusuSavings: false,
		allowStableCurrencyEsusuSavings: false,
		allowStableCurrencyFixedSavings: false,
		allowStableCurrencyFlexibleSavings: false,
		payFixedSavingsRewardBonus: false,
	});

	const [stakeSettings, setStakeSettings] = useState({
		_id: '',
		allowCurrencyStaking: false,
		maxCurrencyStakingAmounts: 0,
		minCurrencyStakingAmounts: 0,
		updateButtonDisabled: true,
	});

	const [swapSettings, setSwapSettings] = useState({
		_id: '',
		swapDefaultSlippage: 0,
		swapTransactionDeadlineMinutes: 0,
		swapTransactionSpeed: '',
		swapUseXendAsFee: false,
		allowSwapping: false,
		updateButtonDisabled: true,
		swapLiquidityProviderUsertypeId: '',
	});

	const [loanSettings, setLoanSettings] = useState({
		_id: '',
		loanMasterUserId: '',
		maxDurationDays: 0,
		minDurationDays: 0,
		minLoanAmountUsd: 0,
		maxLoanAmountUsd: 0,
		updateButtonDisabled: true,
		allowLoans: false,
	});

	const [gameSettings, setGameSettings] = useState({
		_id: '',
		allowGame: false,
		updateButtonDisabled: true,
	});
	const [generalSettings, setGeneralSettings] = useState({
		_id: '',
		xendPrice: 0,
		xendAPR: 0,
		generateNewAddresses: false,
		activeProtocol: '',
		activeNetworkChainId: '',
		updateButtonDisabled: true,
		tonCustodialWalletAddress: '',
	});

	useEffect(() => {
		setSavingsSettings({
			...savingsSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	useEffect(() => {
		setStakeSettings({
			...stakeSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	useEffect(() => {
		setSwapSettings({
			...swapSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	useEffect(() => {
		setLoanSettings({
			...loanSettings,
			...settings,
			updateButtonDisabled: true,
			loanMasterUserId: settings?.loanMasterUserId || '',
		});
	}, [settings]);

	useEffect(() => {
		setGameSettings({
			...gameSettings,
			...settings,
			updateButtonDisabled: true,
		});
	}, [settings]);

	useEffect(() => {
		setGeneralSettings({
			...generalSettings,
			...settings,
			updateButtonDisabled: true,
			generateNewAddresses: settings?.generateNewAddresses || false,
			activeProtocol: settings?.activeProtocol || '',
			activeNetworkChainId: settings?.activeNetworkChainId || '',
			tonCustodialWalletAddress: settings?.tonCustodialWalletAddress || '',
		});
	}, [settings]);

	const updateSavingsSettings = () => {
		const {
			maxAllowedStableCurrencyFlexibleSavingsCount,
			maxAllowedStableCurrencyFixedSavingsCount,
			maxAllowedStableCurrencySavingsTopUpCount,
			updateButtonDisabled,
			xendSavingsRewardTokenAPR,
			flexibleWithdrawalHours,
			maxStableCurrencySavingAmounts,
			minStableCurrencySavingAmounts,
			fixedSavingsTopUpLifespan,
			fixedSavingsTerminationPenaltyPercentage,
			allowFlexibleAutoSave,
			allowFixedAutoSave,
			allowJoinEsusuMultiple,
			allowPublicViewEsusuSavings,
			allowStableCurrencyEsusuSavings,
			allowStableCurrencyFixedSavings,
			allowStableCurrencyFlexibleSavings,
			payFixedSavingsRewardBonus,
		} = savingsSettings;

		dispatch(
			updateInternalSettings({
				settingsId: savingsSettings._id,
				maxAllowedStableCurrencyFlexibleSavingsCount,
				maxAllowedStableCurrencyFixedSavingsCount,
				maxAllowedStableCurrencySavingsTopUpCount,
				updateButtonDisabled,
				xendSavingsRewardTokenAPR,
				flexibleWithdrawalHours,
				maxStableCurrencySavingAmounts,
				minStableCurrencySavingAmounts,
				fixedSavingsTopUpLifespan,
				fixedSavingsTerminationPenaltyPercentage,
				allowFlexibleAutoSave,
				allowFixedAutoSave,
				allowJoinEsusuMultiple,
				allowPublicViewEsusuSavings,
				allowStableCurrencyEsusuSavings,
				allowStableCurrencyFixedSavings,
				allowStableCurrencyFlexibleSavings,
				payFixedSavingsRewardBonus,
			})
		);
	};

	const updateStakingSettings = () => {
		const {
			allowCurrencyStaking,
			maxCurrencyStakingAmounts,
			minCurrencyStakingAmounts,
			updateButtonDisabled,
		} = stakeSettings;

		dispatch(
			updateInternalSettings({
				settingsId: stakeSettings._id,
				allowCurrencyStaking,
				maxCurrencyStakingAmounts,
				minCurrencyStakingAmounts,
				updateButtonDisabled,
			})
		);
	};

	const updateSwapSettings = () => {
		const {
			swapDefaultSlippage,
			swapTransactionDeadlineMinutes,
			swapTransactionSpeed,
			swapUseXendAsFee,
			allowSwapping,
			updateButtonDisabled,
			swapLiquidityProviderUsertypeId,
		} = swapSettings;

		dispatch(
			updateInternalSettings({
				settingsId: swapSettings._id,
				swapDefaultSlippage,
				swapTransactionDeadlineMinutes,
				swapTransactionSpeed,
				swapUseXendAsFee,
				allowSwapping,
				updateButtonDisabled,
				swapLiquidityProviderUsertypeId,
			})
		);
	};

	const updateLoanSettings = () => {
		const {
			loanMasterUserId,
			maxDurationDays,
			minDurationDays,
			minLoanAmountUsd,
			maxLoanAmountUsd,
			updateButtonDisabled,
			allowLoans,
		} = loanSettings;

		dispatch(
			updateInternalSettings({
				settingsId: loanSettings._id,
				loanMasterUserId,
				maxDurationDays,
				minDurationDays,
				minLoanAmountUsd,
				maxLoanAmountUsd,
				updateButtonDisabled,
				allowLoans,
			})
		);
	};

	const updateGeneralSettings = () => {
		const {
			xendPrice,
			xendAPR,
			generateNewAddresses,
			activeProtocol,
			activeNetworkChainId,
			updateButtonDisabled,
			tonCustodialWalletAddress,
		} = generalSettings;

		dispatch(
			updateInternalSettings({
				settingsId: generalSettings._id,
				xendPrice,
				xendAPR,
				generateNewAddresses,
				activeProtocol,
				activeNetworkChainId,
				updateButtonDisabled,
				tonCustodialWalletAddress,
			})
		);
	};

	const updateGameSettings = () => {
		const { allowGame, updateButtonDisabled } = gameSettings;

		dispatch(
			updateInternalSettings({
				settingsId: gameSettings._id,
				allowGame: String(allowGame),
				updateButtonDisabled,
			})
		);
	};
	useEffect(() => {
		dispatch(retrieveInternalSettings());
	}, []);

	return (
		<SavingsSettingsStyle>
			<div>
				<h2>Savings</h2>
				<div className="lined-settings">
					<div>
						<p className="label">
							Max Allowed Stable Currency Fixed Savings Count
						</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									maxAllowedStableCurrencyFixedSavingsCount: Number(
										e.target.value
									),
								})
							}
							value={savingsSettings.maxAllowedStableCurrencyFixedSavingsCount}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">
							Max Allowed Stable Currency Flexible Savings Count
						</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									maxAllowedStableCurrencyFlexibleSavingsCount: Number(
										e.target.value
									),
								})
							}
							value={
								savingsSettings.maxAllowedStableCurrencyFlexibleSavingsCount
							}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">
							Max Allowed Stable Currency Savings TopUp Count
						</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									maxAllowedStableCurrencySavingsTopUpCount: Number(
										e.target.value
									),
								})
							}
							value={savingsSettings.maxAllowedStableCurrencySavingsTopUpCount}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Xend Savings Reward Token APR</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									xendSavingsRewardTokenAPR: Number(e.target.value),
								})
							}
							value={savingsSettings.xendSavingsRewardTokenAPR}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Flexible Withdrawal Hours</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									flexibleWithdrawalHours: Number(e.target.value),
								})
							}
							value={savingsSettings.flexibleWithdrawalHours}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Max Stable Currency Saving Amounts</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									maxStableCurrencySavingAmounts: Number(e.target.value),
								})
							}
							value={savingsSettings.maxStableCurrencySavingAmounts}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Min Stable Currency Saving Amounts</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									minStableCurrencySavingAmounts: Number(e.target.value),
								})
							}
							value={savingsSettings.minStableCurrencySavingAmounts}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Fixed Savings TopUp Lifespan</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									fixedSavingsTopUpLifespan: Number(e.target.value),
								})
							}
							value={savingsSettings.fixedSavingsTopUpLifespan}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">FixedSavings Termination Penalty Percentage</p>
						<Input
							onChange={(e) =>
								setSavingsSettings({
									...savingsSettings,
									updateButtonDisabled: false,
									fixedSavingsTerminationPenaltyPercentage: Number(
										e.target.value
									),
								})
							}
							value={savingsSettings.fixedSavingsTerminationPenaltyPercentage}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<p className="label">Allow Fixed Auto Save</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowFixedAutoSave: value,
							})
						}
						checked={savingsSettings.allowFixedAutoSave}
					/>

					<p className="label">Allow Flexible Auto Save</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowFlexibleAutoSave: value,
							})
						}
						checked={savingsSettings.allowFlexibleAutoSave}
					/>

					<p className="label">Allow Join Esusu Multiple</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowJoinEsusuMultiple: value,
							})
						}
						checked={savingsSettings.allowJoinEsusuMultiple}
					/>

					<p className="label">Allow Public View Esusu Savings</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowPublicViewEsusuSavings: value,
							})
						}
						checked={savingsSettings.allowPublicViewEsusuSavings}
					/>

					<p className="label">Allow Stable Currency Esusu Savings</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowStableCurrencyEsusuSavings: value,
							})
						}
						checked={savingsSettings.allowStableCurrencyEsusuSavings}
					/>

					<p className="label">Allow Stable Currency Fixed Savings</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowStableCurrencyFixedSavings: value,
							})
						}
						checked={savingsSettings.allowStableCurrencyFixedSavings}
					/>

					<p className="label">Allow StableCurrency Flexible Savings</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								allowStableCurrencyFlexibleSavings: value,
							})
						}
						checked={savingsSettings.allowStableCurrencyFlexibleSavings}
					/>

					<p className="label">Pay Fixed Savings Reward Bonus</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSavingsSettings({
								...savingsSettings,
								updateButtonDisabled: false,
								payFixedSavingsRewardBonus: value,
							})
						}
						checked={savingsSettings.payFixedSavingsRewardBonus}
					/>
				</div>
				<div>
					<Button
						type="default"
						onClick={updateSavingsSettings}
						disabled={savingsSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>
				<Divider />

				<h2>Staking</h2>
				<div className="lined-settings">
					<div>
						<p className="label">Max Currency Staking Amounts</p>
						<Input
							onChange={(e) =>
								setStakeSettings({
									...stakeSettings,
									updateButtonDisabled: false,
									maxCurrencyStakingAmounts: Number(e.target.value),
								})
							}
							value={stakeSettings.maxCurrencyStakingAmounts}
						/>
					</div>
				</div>
				<div className="lined-settings">
					<div>
						<p className="label">Min Currency Staking Amounts</p>
						<Input
							onChange={(e) =>
								setStakeSettings({
									...stakeSettings,
									updateButtonDisabled: false,
									minCurrencyStakingAmounts: Number(e.target.value),
								})
							}
							value={stakeSettings.minCurrencyStakingAmounts}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<p className="label">Allow Currency Staking</p>
					<Switch
						size="small"
						onChange={(value) =>
							setStakeSettings({
								...stakeSettings,
								updateButtonDisabled: false,
								allowCurrencyStaking: value,
							})
						}
						checked={stakeSettings.allowCurrencyStaking}
					/>
				</div>
				<div>
					<Button
						type="default"
						onClick={updateStakingSettings}
						disabled={stakeSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>
				<Divider />
				<h2>Swap</h2>
				<div className="lined-settings">
					<div>
						<p className="label">Swap Default Slippage</p>
						<Input
							onChange={(e) =>
								setSwapSettings({
									...swapSettings,
									updateButtonDisabled: false,
									swapDefaultSlippage: Number(e.target.value),
								})
							}
							value={swapSettings.swapDefaultSlippage}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Swap Transaction Deadline Minutes</p>
						<Input
							onChange={(e) =>
								setSwapSettings({
									...swapSettings,
									updateButtonDisabled: false,
									swapTransactionDeadlineMinutes: Number(e.target.value),
								})
							}
							value={swapSettings.swapTransactionDeadlineMinutes}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Swap Transaction Speed</p>
						<Input
							onChange={(e) =>
								setSwapSettings({
									...swapSettings,
									updateButtonDisabled: false,
									swapTransactionSpeed: e.target.value,
								})
							}
							value={swapSettings.swapTransactionSpeed}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Swap Liquidity Provider UsertypeId</p>
						<Input
							onChange={(e) =>
								setSwapSettings({
									...swapSettings,
									updateButtonDisabled: false,
									swapLiquidityProviderUsertypeId: e.target.value,
								})
							}
							value={swapSettings.swapLiquidityProviderUsertypeId}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<p className="label">Swap Use XendAsFee</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSwapSettings({
								...swapSettings,
								updateButtonDisabled: false,
								swapUseXendAsFee: value,
							})
						}
						checked={swapSettings.swapUseXendAsFee}
					/>

					<p className="label">Allow Swapping</p>
					<Switch
						size="small"
						onChange={(value) =>
							setSwapSettings({
								...swapSettings,
								updateButtonDisabled: false,
								allowSwapping: value,
							})
						}
						checked={swapSettings.allowSwapping}
					/>
				</div>
				<div>
					<Button
						type="default"
						onClick={updateSwapSettings}
						disabled={swapSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>

				<Divider />
				<h2>Loan</h2>
				{/* <div className="lined-settings">
					<div>
						<p className="label">Loan Master UserId</p>
						<Input
							onChange={(e) =>
								setLoanSettings({
									...loanSettings,
									updateButtonDisabled: false,
									loanMasterUserId: e.target.value,
								})
							}
							value={loanSettings.loanMasterUserId}
						/>
					</div>
				</div> */}

				<div className="lined-settings">
					<div>
						<p className="label">Max Duration Days</p>
						<Input
							onChange={(e) =>
								setLoanSettings({
									...loanSettings,
									updateButtonDisabled: false,
									maxDurationDays: Number(e.target.value),
								})
							}
							value={loanSettings.maxDurationDays}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Min Duration Days</p>
						<Input
							onChange={(e) =>
								setLoanSettings({
									...loanSettings,
									updateButtonDisabled: false,
									minDurationDays: Number(e.target.value),
								})
							}
							value={loanSettings.minDurationDays}
						/>
					</div>
				</div>
				<div className="lined-settings">
					<div>
						<p className="label">Min Loan Amount Usd</p>
						<Input
							onChange={(e) =>
								setLoanSettings({
									...loanSettings,
									updateButtonDisabled: false,
									minLoanAmountUsd: Number(e.target.value),
								})
							}
							value={loanSettings.minLoanAmountUsd}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Max Loan Amount Usd</p>
						<Input
							onChange={(e) =>
								setLoanSettings({
									...loanSettings,
									updateButtonDisabled: false,
									maxLoanAmountUsd: Number(e.target.value),
								})
							}
							value={loanSettings.maxLoanAmountUsd}
						/>
					</div>
				</div>
				<div className="lined-settings">
					<p className="label">Allow Loans</p>
					<Switch
						size="small"
						onChange={(value) =>
							setLoanSettings({
								...loanSettings,
								updateButtonDisabled: false,
								allowLoans: value,
							})
						}
						checked={loanSettings.allowLoans}
					/>
				</div>

				<div>
					<Button
						type="default"
						onClick={updateLoanSettings}
						disabled={loanSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>
				<Divider />
				<h2>Game</h2>

				<div className="lined-settings">
					<p className="label">Allow Game</p>
					<Switch
						size="small"
						onChange={(value) =>
							setGameSettings({
								...gameSettings,
								updateButtonDisabled: false,
								allowGame: value,
							})
						}
						checked={gameSettings.allowGame}
					/>
				</div>

				<div>
					<Button
						type="default"
						onClick={updateGameSettings}
						disabled={gameSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>

				<Divider />

				<h2>General</h2>
				<div className="lined-settings">
					<div>
						<p className="label">xend Price</p>
						<Input
							onChange={(e) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									xendPrice: Number(e.target.value),
								})
							}
							value={generalSettings.xendPrice}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">xend APR</p>
						<Input
							onChange={(e) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									xendAPR: Number(e.target.value),
								})
							}
							value={generalSettings.xendAPR}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Generate New Addresses</p>
						<Switch
							size="small"
							onChange={(value) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									generateNewAddresses: value,
								})
							}
							checked={generalSettings.generateNewAddresses}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Active Protocol</p>
						<Input
							onChange={(e) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									activeProtocol: e.target.value,
								})
							}
							value={generalSettings.activeProtocol}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Ton Custodial Wallet Address</p>
						<Input
							style={{ width: '250%' }}
							onChange={(e) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									tonCustodialWalletAddress: e.target.value,
								})
							}
							value={generalSettings.tonCustodialWalletAddress}
						/>
					</div>
				</div>

				<div className="lined-settings">
					<div>
						<p className="label">Active Network ChainId</p>
						<Input
							onChange={(e) =>
								setGeneralSettings({
									...generalSettings,
									updateButtonDisabled: false,
									activeNetworkChainId: e.target.value,
								})
							}
							value={generalSettings.activeNetworkChainId}
						/>
					</div>
				</div>

				<div>
					<Button
						type="default"
						onClick={updateGeneralSettings}
						disabled={generalSettings.updateButtonDisabled}
					>
						Update
					</Button>
				</div>
			</div>
		</SavingsSettingsStyle>
	);
};

export default SavingsSettings;
