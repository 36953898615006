import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CurrencyContractAddresses from './CurrencyContractAddresses';
import { useAppSelector } from '../../methods/redux/hooks';
import EditCurrency from './EditCurrency';

const SingleCurrencyStyle = styled.section`
	display: grid;
	background-color: #ffffff;
	padding: 15px;
	margin-bottom: 15px;
	border: 1px solid #f2f2f2;

	& .single-currency-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f2f2f2;
		padding-bottom: 15px;
		padding-top: 15px;
		margin-bottom: 10px;

		& .name {
			display: inline-block;
			margin-left: 10px;

			& p:first-child {
				font-weight: bold;
				font-size: 16px;
			}

			& p:last-child {
				font-size: 12px;
			}
		}
	}

	& .currency-logo {
		width: 30px;
		height: 30px;
	}

	& .settings {
		padding-bottom: 10px;
	}

	& .settings > div {
		display: inline-block;
		padding: 7px;

		& p:first-child {
			font-size: 16px;
		}

		& p:last-child {
			font-size: 13px;
			color: #999999;
		}
	}

	& .contracts {
		padding-top: 12px;
		border-top: 1px solid #f2f2f2;

		& > div > * {
			display: inline-block;
		}

		& > p {
			font-size: 15px;
			opacity: 0.7;
			margin-bottom: 10px;
		}

		& a {
			display: inline-block;
			font-weight: bold;
			font-size: 16px;
			padding: 5px;
			margin-right: 10px;

			&.disabled {
				color: #eb5160;
				cursor: crosshair;
				text-decoration: line-through;
			}
		}
	}
`;

interface ISingleCurrency {
	data: any;
}

const SingleCurrency = ({ data }: ISingleCurrency) => {
	const { contracts, disabledCurrencyWithdrawal } = useAppSelector(
		(store) => store.currencies
	);
	const [tokenContracts, setTokenContracts] = useState<Array<any>>([]);
	const [state, setState] = useState({
		iconUrl: '',
		_id: '',
		name: '',
		symbol: '',
		decimalPrecision: '',
		coinGeckoId: '',
		usdValue: '',
		minimumWithdrawalAmount: '',
		switchWalletId: '',
		currencyForm: '',
	});

	const currencyContracts = () => {
		const x = contracts.filter(
			(item: any) => item.currencyId?._id === state._id
		);
		setTokenContracts(x);
	};

	useEffect(() => {
		setState({ ...state, ...data });
	}, [data]);

	useEffect(() => {
		currencyContracts();
	}, [contracts, state._id]);

	const checkDisabledWithdrawal = (blockchainNetworkId: any) => {
		const record = disabledCurrencyWithdrawal.find(
			(x: any) =>
				x.isDisabled &&
				x.currencyId?._id === state._id &&
				x.blockchainNetworkId?._id === blockchainNetworkId
		);
		return !!record;
	};

	return (
		<SingleCurrencyStyle>
			<div className="single-currency-top">
				<div>
					<img alt="" src={state.iconUrl} className="currency-logo" />
					<div className="name">
						<p>{state.symbol}</p>
						<p>{state.name}</p>
					</div>
				</div>
				<EditCurrency currency={state} />
			</div>
			<div className="settings">
				<div>
					<p>{state.decimalPrecision}</p>
					<p>Decimals</p>
				</div>
				<div>
					<p>{state.minimumWithdrawalAmount}</p>
					<p>Min. Withdrawal Amount</p>
				</div>
				<div>
					<p>{state.usdValue} USD</p>
					<p>USD Value</p>
				</div>
				<div>
					<p>{state.currencyForm}</p>
					<p>Currency Form</p>
				</div>
				{state.currencyForm === 'crypto' && (
					<>
						<div>
							<p>{state.switchWalletId}</p>
							<p>Switch Wallet Id</p>
						</div>
						<div>
							<p>{state.coinGeckoId}</p>
							<p>CoinGecko Id</p>
						</div>
					</>
				)}
			</div>
			{state.currencyForm === 'crypto' && (
				<div className="contracts">
					<div>
						<p>Contract Addresses</p>
						<CurrencyContractAddresses
							currencyId={state._id}
							currencyName={state.symbol}
						/>
					</div>
					<div>
						{tokenContracts.map((contract) => (
							<a
								className={
									checkDisabledWithdrawal(contract.blockchainNetworkId?._id)
										? 'disabled'
										: ''
								}
								title={
									checkDisabledWithdrawal(contract.blockchainNetworkId?._id)
										? 'Disabled Withdrawal'
										: ''
								}
								key={contract._id}
								href={`https://${contract.blockchainNetworkId?.explorerUrl}/token/${contract.contractAddress}`}
								target="_blank"
								rel="noreferrer"
							>
								{contract.blockchainNetworkId?.abbreviation}
							</a>
						))}
					</div>
				</div>
			)}
		</SingleCurrencyStyle>
	);
};

export default SingleCurrency;
