import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { shortAddress } from '../../methods/utils/general-utils';
import ChangeWithdrawalStatus from './ChangeWithdrawalStatus';
import ProcessInAppRequest from './ProcessInAppRequest';
import ProcessRequest from './ProcessRequest';
import ReverseFunds from './ReverseFunds';

interface IRequestItem {
	request: any;
}

const Style = styled.div`
	background-color: #f8f8f8;
	padding: 5px 10px;
	margin-bottom: 10px;

	& .main-info {
		display: flex;
		border-bottom: 1px dashed #ddd;
		padding: 10px 0;
		flex-wrap: wrap;

		& > * {
			min-width: 200px;
		}

		& .user {
			font-weight: 600;
			font-size: 18px;
		}

		& .amount {
			font-weight: 600;
			font-size: 18px;
		}
	}

	& .details {
		padding: 15px 0;

		& > * {
			display: inline-block;
			min-width: 200px;
			margin-bottom: 7px;
			padding: 5px;
		}

		& .label {
			font-size: 13px;
			color: #808080;
			font-style: italic;
		}
	}

	& .xendbrige-ref {
		word-wrap: break-word;
	}
`;

const WithdrawalRequestItem = ({ request }: IRequestItem) => {
	const interpretStatus = (statusValue: any) => {
		switch (statusValue) {
			case '0':
				return 'Pending';
			case '1':
				return 'Completed';
			case '2':
				return 'Failed';
			case '3':
				return 'Cancelled';
			case '4':
				return 'Deleted';
			case '5':
				return 'Processing';
			case '6':
				return 'Retry';
			case '7':
				return 'On-Hold';
			case '8':
				return 'Review';
			default:
				return 'Unknown';
		}
	};

	return (
		<Style>
			<div className="main-info">
				<div className="user">
					<Link
						title="View Profile"
						to={`/user-profile/${request.userId?._id}`}
					>
						@{request.userId?.username}
					</Link>
				</div>
				<div>
					<p className="amount">
						{request.finalAmount} {request.currencyId?.symbol}
					</p>
				</div>
				<div>
					<Tag>{interpretStatus(String(request.status))}</Tag>
				</div>
			</div>
			<div className="details">
				<div>
					<a
						target="_blank"
						href={`https://${request.blockchainNetworkId?.explorerUrl}/address/${request.destinationAddress}#tokentxns`}
						rel="noreferrer"
					>
						{shortAddress(request.destinationAddress)}
					</a>
					<p className="label">
						Destination{' '}
						{request.withdrawalAddressWhiteListId && '(whitelisted)'}
					</p>
				</div>
				<div>
					<p>{request.blockchainNetworkId?.abbreviation}</p>
					<p className="label">Blockchain Network</p>
				</div>
				<div>
					{request.transactionHash ? (
						<a
							href={`https://${request.blockchainNetworkId?.explorerUrl}/tx/${request.transactionHash}`}
							target="_blank"
							rel="noreferrer"
						>
							Link to Explorer
						</a>
					) : (
						'--'
					)}
					<p className="label">Hash</p>
				</div>
				<div>
					<p>
						{request.fee} {request.currencyId?.symbol}
					</p>
					<p className="label">Fee</p>
				</div>
				<div>
					<p className="xendbrige-ref">
						{request.p2pRef ? request.p2pRef : '--'}
					</p>
					<p className="label">XendBridge Ref.</p>
				</div>
				<div>
					<p className="xendbrige-ref">
						{request.blockchainMemo ? request.blockchainMemo : ''}
					</p>
					<p className="label">BlockChain Memo</p>
				</div>
				<div>
					{request.processingChannel === 3 && <p>--</p>}
					{request.processingChannel === 2 && <p>Robot</p>}
					{request.processingChannel === 1 && <p>Manual</p>}
					<p className="label">Processing Channel</p>
				</div>
				<div>
					<p>{request.processedBy ? request.processedBy?.email : '--'}</p>
					<p className="label">Processed By</p>
				</div>
				<div>
					<p>
						{request.lastStatusChangedByUserId
							? request.lastStatusChangedByUserId?.email
							: '--'}
					</p>
					<p className="label">Last Status Changed By</p>
				</div>
				<div>
					<p>
						{request.processedAt
							? moment(request.processedAt).format('MMM DD, YY / hh:mm:ss a')
							: '--'}
					</p>
					<p className="label">Processed At</p>
				</div>
				{request.inAppTransaction && (
					<div>
						<p>
							<span
								className="material-icons"
								style={{ color: 'var(--green-color)' }}
							>
								check
							</span>
						</p>
						<p className="label">InApp Transaction</p>
					</div>
				)}
				<div>
					<div style={{ display: 'flex' }}>
						<img
							alt=""
							src={request.currencyId?.iconUrl}
							style={{ height: 20, width: 20, marginRight: 6 }}
						/>
						<p>
							{request.formerBalance} {request.currencyId?.symbol}{' '}
						</p>
					</div>
					<p className="label">Prev. Balance</p>
				</div>
				<div>
					<p>{moment(request.updatedAt).format('MMM DD, YYYY h:mm:ss a')} </p>
					<p>({moment(request.updatedAt).fromNow()})</p>
					<p className="label">Updated</p>
				</div>
				<div>
					<p>{moment(request.createdAt).format('MMM DD, YYYY h:mm:ss a')}</p>
					<p>({moment(request.createdAt).fromNow()})</p>
					<p className="label">Created</p>
				</div>
				<div>
					{Number(request.status) !== 1 && (
						<ChangeWithdrawalStatus request={request} />
					)}

					{Number(request.status) >= 5 && Number(request.status) <= 7 && (
						<>
							<ProcessRequest request={request} />

							<ProcessInAppRequest request={request} />
						</>
					)}
				</div>
				{Number(request.status) >= 6 && Number(request.status) <= 8 && (
					<div>
						<ReverseFunds item={request} />
					</div>
				)}
			</div>
		</Style>
	);
};

export default WithdrawalRequestItem;
