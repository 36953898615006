import { Modal, Button, Input, Popconfirm, Select } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import {
	addCustodialWallet,
	removeCustodialWallet,
} from '../../methods/redux/reducers/general';

const Style = styled.div`
	& .title {
		display: flex;
		justify-content: space-between;
	}

	& .single {
		padding: 10px 0;
		border-bottom: 1px solid #ddd;
	}

	& .action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 7px;
		font-size: 11.5px;
	}
`;

const ManageCustodialWallets = () => {
	const dispatch = useAppDispatch();
	const [visible, setVisible] = useState(false);
	const { custodialWallets } = useAppSelector((store) => store.general);
	const [state, setState] = useState({
		address: '',
		description: '',
		chainType: '',
	});

	const submitNewWallet = async () => {
		dispatch(addCustodialWallet(state));
	};

	const removeWallet = (addressId: any) => {
		dispatch(removeCustodialWallet(addressId));
	};

	const items = [
		{
			key: 'evm',
			label: 'evm',
		},
		{
			key: 'ton',
			label: 'ton',
		},
		{
			key: 'tron',
			label: 'tron',
		},
		{
			key: 'solana',
			label: 'solana',
		},
	];

	return (
		<Style>
			<div className="title">
				<h2>Manage Custodial Wallets</h2>
				<Button type="primary" onClick={() => setVisible(true)}>
					Add Wallet
				</Button>
			</div>

			<div>
				{custodialWallets.itemsList.map((item: any) => (
					<div key={item._id} className="single">
						<p>
							<strong>Address: </strong>
							<i>{item.address}</i>
						</p>

						<p style={{ marginTop: '10px' }}>
							<strong>Description: </strong>
							<i>{item.description}</i>
						</p>

						<p style={{ marginTop: '10px' }}>
							<strong>Chain Type: </strong>
							<i>{item.chainType}</i>
						</p>

						<div className="action">
							<p>{moment(item.updatedAt).format('lll')}</p>
							<Popconfirm
								title="Are you sure?"
								onConfirm={() => removeWallet(item._id)}
							>
								<Button size="small" danger>
									Delete
								</Button>
							</Popconfirm>
						</div>
					</div>
				))}
			</div>

			<Modal
				okText="Add Wallet"
				title="Add Custodial Wallet"
				onOk={submitNewWallet}
				visible={visible}
				onCancel={() => setVisible(false)}
			>
				<p>Custodial Wallet Address</p>
				<Input
					value={state.address}
					onChange={(e) => setState({ ...state, address: e.target.value })}
				/>
				<p>Chain Type</p>
				<Select
					defaultValue={state.chainType}
					style={{ width: '100%' }}
					onChange={(value) => setState({ ...state, chainType: value })}
				>
					{items.map((i: any) => (
						<Select.Option key={i.key} value={i.key}>
							{i.label}
						</Select.Option>
					))}
				</Select>
				<p>Description</p>
				<Input.TextArea
					onChange={(e) => setState({ ...state, description: e.target.value })}
					value={state.description}
				/>
			</Modal>
		</Style>
	);
};

export default ManageCustodialWallets;
