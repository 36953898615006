import { useState } from 'react';
import { Button, Modal, Select } from 'antd';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../methods/redux/hooks';
import { updateInternalSettings } from '../../methods/redux/reducers/internal-settings';

const Style = styled.div`
	margin-top: 10px;
	display: flex;
	align-items: center;
`;

const EditApySource = () => {
	const dispatch = useAppDispatch();
	const { apySource } = useAppSelector((store) => store.internalSettings);
	const [visible, setVisible] = useState(false);
	const [newApySource, setNewApySource] = useState('');

	const submit = () => {
		dispatch(updateInternalSettings({ apySource: newApySource }));
	};

	const decimalOptions = [
		{ name: 'fortube', value: 'fortube' },
		{ name: 'venus', value: 'venus' },
		{ name: 'yearn', value: 'yearn' },
		{ name: 'xauto-busd', value: 'xauto-busd' },
	];
	return (
		<Style>
			<div>
				<p>
					<strong>{apySource}</strong>
				</p>
			</div>
			<div>
				<Button type="text" onClick={() => setVisible(true)}>
					Edit
				</Button>
				<Modal
					onOk={submit}
					title="Change APY source"
					visible={visible}
					onCancel={() => setVisible(false)}
				>
					<p>Get the source name from the values in apy cronjob.</p>
					<p>Source name</p>
					{/* <Input
						value={newApySource}
						onChange={(e) => setNewApySource(e.target.value)}
					/> */}
					<Select
						size="large"
						value={newApySource}
						style={{ width: '100%', marginBottom: 5 }}
						onChange={(val) => setNewApySource(val)}
					>
						<Select.Option value="">--</Select.Option>
						{decimalOptions.map((dec) => (
							<Select.Option key={dec.value} value={dec.value}>
								{dec.name}
							</Select.Option>
						))}
					</Select>
				</Modal>
			</div>
		</Style>
	);
};

export default EditApySource;
