import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import baseuri from '../../baseuri';

export const getCurrencies = createAsyncThunk(
	'currencies/getCurrencies',
	async (params: any) => {
		const res = await baseuri.get('/admin/currency', {
			params: { ...params, pageId: 1, perPage: 20 },
		});
		const records = res.data.data?.itemsList;
		if (records) {
			return records;
		}
		return [];
	}
);

export const updateSupportedCurrency = createAsyncThunk(
	'currencies/updateSupportedCurrency',
	async (data: any) => {
		const res = await baseuri.put('/admin/currency/', data);
		return res.data.data;
	}
);

export const getBlockchainNetworks = createAsyncThunk(
	'currencies/getBlockchainNetworks',
	async () => {
		const res = await baseuri.get('/admin/blockchain-network', {
			params: {
				pageId: 1,
				perPage: 20,
			},
		});
		const networks = res.data.data?.itemsList;
		if (networks) {
			return networks;
		}
		return [];
	}
);

export const updateABlockchainNetwork = createAsyncThunk(
	'currencies/updateABlockchainNetwork',
	async (data: any) => {
		try {
			const res = await baseuri.put('/admin/blockchain-network', data);
			message.success('Blockchain network update successful');
			const update = res.data.data;
			return update;
		} catch (error: any) {
			return message.error(error.response?.data?.error_details);
		}
	}
);
export const getContractAddress = createAsyncThunk(
	'currencies/getContractAddress',
	async (params: any) => {
		const res = await baseuri.get('/admin/contract-address', {
			params: { ...params },
		});

		const addresses = res.data.data;

		if (addresses) {
			return addresses;
		}
		return [];
	}
);

export const getDisabledCurrencyWithdrawals = createAsyncThunk(
	'currencies/getDisabledCurrencyWithdrawals',
	async (params: any) => {
		const res = await baseuri.get('/admin/currency/disabled-currencies', {
			params: { ...params },
		});

		return res.data.data;
	}
);

export const updateContractAddress = createAsyncThunk(
	'currencies/updateContractAddress',
	async (data: any) => {
		await baseuri.post('/admin/contract-address', data);
		message.success('Contract Address updated successfully');
	}
);

export const deleteContractAddress = createAsyncThunk(
	'currencies/updateContractAddress',
	async (params: any) => {
		await baseuri.delete('/admin/contract-address', { params });
		message.success('Contract Address removed successfully');
	}
);

export const toggleDisableWithdrawal = createAsyncThunk(
	'currencies/toggleDisableWithdrawal',
	async (data: any, { dispatch }) => {
		await baseuri.put('/admin/currency/toggle-allow-withdrawal', data);
		message.success('Contract Address removed successfully');
		dispatch(getDisabledCurrencyWithdrawals({ isDisabled: 'true' }));
	}
);

export const retrieveFiatCustodialAccounts = createAsyncThunk(
	'currencies/retrieveFiatCustodialAccounts',
	async (params: any) => {
		const res = await baseuri.get('/admin/fiat-custodial-accounts', {
			params: { ...params },
		});

		return res.data.data;
	}
);

export const retrieveFiatSupportedBanks = createAsyncThunk(
	'currencies/retrieveFiatSupportedBanks',
	async (params: any) => {
		const res = await baseuri.get('/admin/fiat-banks', {
			params: { ...params },
		});

		return res.data.data;
	}
);

const currencyRx = createSlice({
	name: 'currencies',
	initialState: {
		currencies: [],
		loading: false,
		blockchains: [],
		blockchainActivity: false,
		contracts: [],
		disabledCurrencyWithdrawal: [],
		custodialAccounts: {
			itemsList: [],
			paginator: {
				currentPage: 1,
			},
		},
		fiatBanks: {
			itemsList: [],
			paginator: {
				currentPage: 1,
			},
		},
	},
	reducers: {},
	extraReducers(builder) {
		builder
			.addCase(getCurrencies.fulfilled, (store, action) => {
				const state = store;
				state.currencies = action.payload;
			})
			.addCase(getBlockchainNetworks.pending, (store) => {
				const state = store;
				state.blockchainActivity = true;
			})
			.addCase(getBlockchainNetworks.fulfilled, (store, action) => {
				const state = store;
				state.blockchains = action.payload;
				state.blockchainActivity = false;
			})
			.addCase(getDisabledCurrencyWithdrawals.fulfilled, (store, action) => {
				const state = store;
				state.disabledCurrencyWithdrawal = action.payload;
			})
			.addCase(getContractAddress.fulfilled, (store, action) => {
				const state = store;
				state.contracts = action.payload;
			})
			.addCase(updateABlockchainNetwork.pending, (store) => {
				const state = store;
				state.blockchainActivity = true;
			})
			.addCase(updateABlockchainNetwork.fulfilled, (store, action) => {
				const state = store;
				const updatedRecord: any = state.blockchains.map((item: any) => {
					if (item._id === action.payload?._id) {
						return { ...item, ...action.payload };
					}
					return item;
				});
				state.blockchains = updatedRecord;
				state.blockchainActivity = false;
			})
			.addCase(updateSupportedCurrency.pending, (store) => {
				const state = store;
				state.loading = true;
			})
			.addCase(updateSupportedCurrency.rejected, (store) => {
				const state = store;
				state.loading = false;
			})
			.addCase(updateSupportedCurrency.fulfilled, (store, action) => {
				const state = store;
				state.loading = false;
				const updated = action.payload;
				const newData: any = state.currencies.map((currency: any) => {
					if (currency._id === updated._id) {
						return updated;
					}
					return currency;
				});
				state.currencies = newData;
				message.success('Currency updated');
			})
			.addCase(retrieveFiatCustodialAccounts.fulfilled, (store, action) => {
				const state = store;
				state.custodialAccounts = action.payload;
			})
			.addCase(retrieveFiatSupportedBanks.fulfilled, (store, action) => {
				const state = store;
				state.fiatBanks = action.payload;
			});
	},
});

export default currencyRx.reducer;
